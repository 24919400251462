






































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { getAdvice } from "@/request/custom";

@Component({})
export default class Name extends mixins(listMixin) {
  @Prop()
  private selectVal: any; // 选择的值
  @Watch("selectVal", { immediate: true })
  private selectValChange() {
    if (this.selectVal) {
      this.value = JSON.parse(JSON.stringify(this.selectVal));
    }
  }
  @Prop()
  private ifShow: any; // 弹框是否显示
  @Watch("ifShow", { immediate: true })
  private ifShowChange() {
    if (this.ifShow) {
      this.getList();
    }
    this.visible = this.ifShow;
  }
  private value: any = [];
  private visible: any = true;
  private searchText: any = "";
  private data: any = [];
  private cancel() {
    this.$emit("cancel");
  }
  private exitIndex(item: any) {
    const index = this.value.findIndex((val: any) => {
      return val.advice_id == item.advice_id;
    });
    return index;
  }
  private makeSure() {
    let d: any = JSON.parse(JSON.stringify(this.value));
    this.$emit("change", d);
  }
  private selectOne(item: any) {
    // 如果已经有则去掉，如果没有则添加
    const i = this.exitIndex(item);
    if (i == -1) {
      this.value.push(item);
    } else {
      this.value.splice(i, 1);
    }
  }
  getList() {
    this.ifShowEmpty = false;
    const params: any = {
      search: this.searchText,
      current_page: this.filters.current_page,
      page_count: this.filters.page_count,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });

    getAdvice(this, params)
      .then((data: any) => {
        loading.close();
        this.data = data.advices;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
}
