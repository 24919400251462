































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getReportDetail, savReport, saveWeixianyinsu } from "@/request/custom";
import { getOptions } from "@/request/doctor";
import MainHeader from "../../../main/components/main-header/main-header.vue";
import NavLeft from "../../../main/components/nav-left/nav-left.vue";
import DsCarousel from "@/components/ds-carousel/ds-carousel.vue";
import LeaveConfirm from "@/components/dialogs/leaveConfirmDialog.vue";
import SelectDuoxuan from "@/components/select/select-duoxuan.vue";
import Tuijianyijian from "@/components/tuijianyijian/tuijianyijian.vue";
import Empty from "@/components/empty.vue";
Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate",
]);
@Component({
  components: {
    MainHeader,
    NavLeft,
    DsCarousel,
    LeaveConfirm,
    SelectDuoxuan,
    Tuijianyijian,
    Empty,
  },
})
export default class Name extends Vue {
  private selectVal: any = [];
  private ifShowSelect: any = false;
  private ifShowTuijian: any = false;
  private selectData: any = {};
  private pageType: any = ""; // 编辑 or 新增
  private data: any = {};
  private ifShowSaveConfirm: any = false;
  private noSaveText: any = "不保存，离开";
  private IfShowAddFangan: any = false;
  private addValue: any = "";
  private path: any = "";
  private disabledDate: any = this.endDate();
  private endDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        return time.getTime() > Date.now();
      },
    };
  }

  private get patient() {
    return this.$store.state.patient;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private get patientFileFilter() {
    return this.$store.state.patientFileFilter;
  }
  private goXinzangwuyouReport(item: any) {
    // this.$router.push({
    //   path: "/main/workstation/patient-xinzangwuyou-report",
    //   query: {
    //     id: item.content_id,
    //   },
    // });
    window.open(
      window.location.href.split("/#/")[0] +
        "/#/main/workstation/patient-xinzangwuyou-report?id=" +
        item.content_id
    );
  }
  private goReport(item: any) {
    const url: any = window.location.href.split("/#/")[0];
    window.open(url + "/#/main/report/ceping-report?id=" + item.content_id);
  }
  private openDingzhifangan() {
    this.IfShowAddFangan = true;
  }
  private goHome() {
    this.$router.push("/main/workstation/patient-file");
  }
  private addSure() {
    if (!this.addValue) {
      this.$message.warning("请选择方案类型！");
      return;
    }
    this.IfShowAddFangan = false;
    this.$router.push({
      path: "/main/workstation/patient-ganyu-edit",
      query: {
        type: this.addValue,
      },
    });
    this.$store.commit("updateIfCompose", true);
  }
  private delTuijian(index: any) {
    this.$confirm("是否删除该条记录", "删除", {
      customClass: "commonConfirm",
    })
      .then(() => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.data["推荐意见"].splice(index, 1);
      })
      .catch(() => {});
  }
  private yulan() {}
  private openAddWeixianyinzi() {
    const params: any = {
      data_type: "危险因素",
    };
    getOptions(this, params).then((res: any) => {
      this.selectData = res;
      const d: any = JSON.parse(JSON.stringify(this.data["危险因素"]));
      d.forEach((ele: any) => {
        ele.value = ele.index_name;
      });
      this.selectVal = d;
      this.ifShowSelect = true;
    });
    // this.selectData = getZhibiaoOptions("危险因子");
    // this.ifShowSelect = true;
  }
  private openAddTuijian() {
    this.ifShowTuijian = true;
  }
  private cancelSelect() {
    this.ifShowSelect = false;
  }
  private cancelTuijian() {
    this.ifShowTuijian = false;
  }
  private selectChange(e: any) {
    let arr: any = [];
    e.forEach((ele: any) => {
      arr.push(ele.value);
    });
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      patient_id: this.patient.patient_id,
      index_names: arr,
      is_save: false,
    };
    saveWeixianyinsu(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data["危险因素"] = res;
        this.cancelSelect();
      })
      .catch(() => {
        loading.close();
      });
  }
  private tuijianChange(e: any) {
    this.data["推荐意见"] = e;
    this.cancelTuijian();
  }
  private delWeixianyinzi(index: any) {
    this.$confirm("是否删除该危险因素", "删除", {
      customClass: "commonConfirm",
    })
      .then(() => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.data["危险因素"].splice(index, 1);
      })
      .catch(() => {});
  }
  private edit() {
    this.$store.commit("updateIfCompose", true);
  }
  private save() {
    return new Promise((resolve, reject) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      savReport(this, this.data, loading)
        .then((res: any) => {
          loading.close();
          this.$store.commit("updateIfCompose", false);
          this.$message.success("保存成功");
          this.getDetail();
          resolve(true);
        })
        .catch(() => {
          loading.close();
        });
    });
  }
  private getDetail() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      assess_report_id: this.data.assess_report_id,
    };
    getReportDetail(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data = res;
      })
      .catch(() => {
        loading.close();
      });
  }
  // 保存确认弹框保存
  private changeSave() {
    this.ifShowSaveConfirm = false;
    this.save().then(() => {
      if (this.path == "/login") {
        this.$store.commit("updateToken", "");
        this.$store.commit("updateUser", null);
        this.$router.replace("/login");
        setTimeout(() => {
          location.reload();
        }, 1000);
      } else {
        this.$router.push(this.path);
      }
      // this.$router.back();
    });
  }
  // 保存确认弹框不保存,切换
  private changeConfirmed() {
    this.ifShowSaveConfirm = false;
    this.$store.commit("updateIfCompose", false);
    if (this.path == "/login") {
      this.$store.commit("updateToken", "");
      this.$store.commit("updateUser", null);
      this.$router.replace("/login");
      setTimeout(() => {
        location.reload();
      }, 1000);
    } else {
      this.$router.push(this.path);
    }
    // this.$router.back();
  }
  /**
   * @description 离开页面前保存
   */
  private beforeRouteLeave(to: any, from: any, next: any) {
    this.path = to.fullPath;
    if (this.ifCompose) {
      this.ifShowSaveConfirm = true;
      next(false);
    } else {
      next(true);
    }
  }
  private mounted() {
    if (this.$route.query.id) {
      this.data.assess_report_id = this.$route.query.id;
      this.getDetail();
    }
  }
}
